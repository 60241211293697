interface Config {
  firebase: FirebaseConfig
  loginUrl: LoginUrl
}

interface FirebaseConfig {
  apiKey: string
  authDomain: string
}

interface LoginUrl {
  pocket: string
  hive: string
}

const stagingDomain = 'au-staging.login.papercut.software'
const testDomain = 'au-test.login.papercut.software'
const prodDomain = 'login.papercut.com'
const stagingFirebaseDomain = 'pc-pd-identity-au-staging-1.firebaseapp.com'
const testFirebaseDomain = 'pc-pd-identity-au-test-1.firebaseapp.com'
const prodFirebaseDomain = 'pc-pd-identity-us-prod-1.firebaseapp.com'

const testConfig = {
  firebase: {
    apiKey: 'AIzaSyDqf_Sg1GkXpbds_MRWMP-o66pksZMrKto',
    authDomain: testDomain,
  },
  loginUrl: {
    pocket: 'https://au-staging.pocket.papercut.software/login',
    hive: 'https://au-staging.hive.papercut.software/login',
  },
}
const stagingConfig = {
  firebase: {
    apiKey: 'AIzaSyDOPWlV8H25-eqqDHo7nfm9paF84dIXErw',
    authDomain: stagingDomain,
  },
  loginUrl: {
    pocket: 'https://au-staging.pocket.papercut.software/login',
    hive: 'https://au-staging.hive.papercut.software/login',
  },
}
const prodConfig = {
  firebase: {
    apiKey: 'AIzaSyAM2EGnVwfkCjUeO0O1rAuuxfMnx33oI94',
    authDomain: prodDomain,
  },
  loginUrl: {
    pocket: 'https://pocket.papercut.com/login',
    hive: 'https://hive.papercut.com/login',
  },
}

const localhostConfig = {
  firebase: {
    apiKey: process?.env?.NEXT_PUBLIC_FIREBASE_API_KEY
      ? process.env.NEXT_PUBLIC_FIREBASE_API_KEY
      : 'AIzaSyDOPWlV8H25-eqqDHo7nfm9paF84dIXErw',
    authDomain: process?.env?.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN
      ? process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN
      : 'pc-pd-identity-au-staging-1.firebaseapp.com',
    // using the firebaseapp.com domain instead the custom domain to assist with using the auth redirect flow locally as the custom domain has issues with firebase SDK storing and retrieving logged in state cross domain
    // UPDATE:
    // As of June 18th 2024 the solution of using the firebaseapp.com domain no longer works https://github.com/firebase/firebase-js-sdk/issues/7342#issuecomment-2074016215
    // IAM-1199 has alternative suggestions in it such as using a reverse proxy and setting the authDomain to localhost instead
  },
  loginUrl: {
    pocket: `/?product=pocket&redirectUrl=https%3A%2F%2Fau-staging.pocket.papercut.software%2Fauthenticating%3FauthProcess%3Dlogin`,
    hive: `/?product=hive&redirectUrl=https%3A%2F%2Fau-staging.hive.papercut.software%2Fauthenticating%3FauthProcess%3Dlogin`,
  },
}
const allConfigs = new Map<string, Config>([
  ['localhost', localhostConfig],
  ['127.0.0.1', localhostConfig],
  [testDomain, testConfig],
  [testFirebaseDomain, testConfig],
  [stagingDomain, stagingConfig],
  [stagingFirebaseDomain, stagingConfig],
  [prodDomain, prodConfig],
  [prodFirebaseDomain, prodConfig],
])

export const config = (): Config => {
  let hostname = ''
  if (typeof window !== 'undefined') {
    hostname = window.location.hostname
  }

  if (!allConfigs.has(hostname)) {
    throw new Error(`no config found for hostname ${hostname}`)
  }

  return allConfigs.get(hostname)!
}
